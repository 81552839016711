import compareDesc from 'date-fns/compareDesc';

import { incidentImpactLevels } from './constants';

import CustomDnbDoor from './assets/icons/custom_dnb_door.svg';
import CustomDnbMoneyBag from './assets/icons/custom_dnb_money_bag.svg';
import CustomDnbWallet from './assets/icons/custom_dnb_wallet.svg';
import CustomDnbCalendar from './assets/icons/custom_dnb_calendar.svg';
import CustomDnbStatus from './assets/icons/custom_dnb_status.svg';
import CustomDnbCreditcard from './assets/icons/custom_dnb_creditcard.svg';
import CustomDnbMobileBank from './assets/icons/custom_dnb_mobilebank.svg';
import CustomDnbSpare from './assets/icons/custom_dnb_spare.svg';

import CustomDnbOperational from './assets/icons/custom_dnb_operational.svg';
import CustomDnbMinor from './assets/icons/custom_dnb_minor.svg';
import CustomDnbMajor from './assets/icons/custom_dnb_major.svg';
import CustomDnbCritical from './assets/icons/custom_dnb_critical.svg';
import CustomDnbUnderMaintenance from './assets/icons/custom_dnb_under_maintenance.svg';

export function getIcon(icon) {
  switch (icon) {
    case 'Andre tjenester privat':
    case 'Other services personal':
    case 'Andre tjenester business':
    case 'Other services business':
      return CustomDnbMoneyBag;
    case 'wallet':
    case 'Nettbank':
      return CustomDnbWallet;
    case 'credit-card':
    case 'Minibanker, kort og betalingsterminaler':
      return CustomDnbCreditcard;
    case 'log-in':
    case 'Innlogging':
      return CustomDnbDoor;
    case 'calendar':
      return CustomDnbCalendar;
    case 'status':
      return CustomDnbStatus;
    case 'mobile-bank':
    case 'Mobilbank':
    case 'Sbanken Mobilbank':
      return CustomDnbMobileBank;
    case 'Spare':
      return CustomDnbSpare;
    default:
      return null;
  }
}

export function getIncidientStatus(status) {
  switch (status) {
    case 'operational':
      return 'ok';
    case 'partial_outage':
      return 'partial';
    case 'degraded_performance':
      return 'partial';
    case 'major_outage':
      return 'down';
    case 'under_maintenance':
      return 'partial';
    default:
      break;
  }
}

// TODO Sette et fornuftig default ikon
export function getStatusIcon(status) {
  switch (status) {
    case 'operational':
    case 'none':
      return CustomDnbOperational;
    case 'minor':
    case 'minor_outage':
    case 'degraded_performance':
    case 'partial_outage':
      return CustomDnbMinor;
    case 'major':
    case 'major_outage':
      return CustomDnbMajor;
    case 'critical':
      return CustomDnbCritical;
    case 'under_maintenance':
      return CustomDnbUnderMaintenance;

    default:
      return CustomDnbOperational;
  }
}

/* Helper function that merges a groups incidents and its sub-components incidents. Returns an array of all incidents */
export function mergeIncidents(item) {
  let arr = [];
  for (let sub of item.subComponents) {
    for (let incident of sub.incidents) {
      arr.push(incident);
    }
  }

  let allIncidents = [...item.incidents, ...arr].sort((a, b) => {
    return compareDesc(new Date(a.created_at), new Date(b.created_at));
  });

  return allIncidents;
}

export function addIncidentsToGroup(groups, incidents) {
  let groupsCopy = [...groups];
  /* Iterate through all incidents, we need to attach them to the correct component */
  for (let incident of incidents) {
    for (let component of incident.components) {
      let correctGroup;

      correctGroup = groupsCopy.find((e) => e.id === component.id);

      if (correctGroup) {
        correctGroup.incidents.push(incident);
      } else {
        /* If incident is not in one of our groups, check subgroups/components, and push to correct one */
        for (let group of groupsCopy) {
          let found = group.subComponents.find((el) => el.id === component.id);
          if (found) {
            if (!group.incidents.some((e) => e.id === incident.id)) {
              group.incidents.push(incident);
            }
          }
        }
      }
    }
  }
  return groupsCopy;
}

export function isBankIDIncident(incident) {
  return incident.name.toLowerCase().includes('bankid');
}

// To handle tab routing
export function extractFirstPartOfPath(path) {
  return '/' + path.split('/')[1];
}